import React, { useState, useEffect } from "react";

import { Navigation } from "./navigation";
import { Policy } from "./policy";
import { Contact } from "./contact";


const PolicyLayout = () => {
  return (
    <>
      <Navigation />
      <Policy  />
      <Contact  />
    </>
      
    );
  };
  
  export default PolicyLayout;