import { BrowserRouter, Routes, Route } from "react-router-dom";
import SmoothScroll from "smooth-scroll";
import "./App.css";

//main landing 
import LandingMainLayout from "./components/mainLayout";
import PolicyLayout from "./components/policyLayout";

//Nets sample
import NetsQrSampleLayout from "./components/netsQrSampleLayout";
import ENetsSampleLayout from "./components/enets/enetsSampleLayout";
import SuccessLayout from "./components/enets/successSampleLayout";
import FailLayout from "./components/enets/failSampleLayout";

//open api module
import MainLayout from "./open_api_module/pages/mainLayout";
import Documentation from "./open_api_module/pages/documentLayout";
import IntroductionApi from "./open_api_module/components/views/introductionApi";
import GetAMerchant from "./open_api_module/components/views/merchant/getAMerchant";
import GetMerchants from "./open_api_module/components/views/merchant/getMerchants";
import GetMerchantCategory from "./open_api_module/components/views/merchant_category/getMerchantCat";
import GetCategoryTags from "./open_api_module/components/views/merchant_category/getCategoryTag";
import GetASupplier from "./open_api_module/components/views/supplier/getASupplier";
import GetSuppliers from "./open_api_module/components/views/supplier/getSuppliers";
import GetAProduct from "./open_api_module/components/views/eMenu_product/getAProductCat";
import GetProducts from "./open_api_module/components/views/eMenu_product/getProducts";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <div>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingMainLayout />} />
          <Route path="/policy" element={<PolicyLayout />} />
          <Route path="/nets-qr" element={<NetsQrSampleLayout />} />
          <Route path="/enets" element={<ENetsSampleLayout />} />
          <Route path="/enets/success" element={<SuccessLayout />} />
          <Route path="/enets/fail" element={<FailLayout />} />

          <Route path="/documentation" element={<MainLayout />}>
            <Route index element={<Documentation />} />
            <Route path="/documentation/introduction" element={<IntroductionApi />} />
            <Route path="/documentation/merchant/get-a-merchant" element={<GetAMerchant />} />
            <Route path="/documentation/merchant/get-merchants" element={<GetMerchants />} />
            <Route path="/documentation/merchant-category/get-merchant-category" element={<GetMerchantCategory />} />
            <Route path="/documentation/merchant-category/get-category-tags" element={<GetCategoryTags />} />
            <Route path="/documentation/supplier/get-supplier" element={<GetASupplier />} />
            <Route path="/documentation/supplier/get-suppliers" element={<GetSuppliers />} />
            <Route path="/documentation/emenu/get-product-category" element={<GetAProduct />} />
            <Route path="/documentation/emenu/get-products-category" element={<GetProducts />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
