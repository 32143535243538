import React, { useState, useEffect } from "react";

import { Navigation } from "../navigation";
// import ENetsSample  from "../enets/enetsSample_philicia";
import ENetsSample from "./enetsSample";
import { Contact } from "../contact";


const ENetsSampleLayout = () => {
  return (
    <>
      <Navigation />
      <ENetsSample />
      <Contact />
    </>

  );
};

export default ENetsSampleLayout;