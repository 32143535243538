import { useSelector, useDispatch, Provider } from 'react-redux'
import Intro from '../components/views/introductionApi'
import styles from '../styles/styles.module.css'

const Documentation = () => {
  return (
    <div id="main" className={styles.row}>
    <Intro />
    </div>    
  )
}
export default Documentation