import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      {/* <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row" style={{textAlign: 'center'}}>
        {/* <div className="col-xs-12 col-md-6">
        </div>
        <div className="col-xs-12 col-md-6"> */}
          <h2>Achievements Timeline</h2>
        {/* </div> */}
      </div>
      
      <div class="container">
        <div class="main-timeline">
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2023</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">Open API PaaS for F&B, Retail, Health, Youth & Community sectors</h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
          </div>
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2023</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">15 Youths: digital Hawker Centre launchpad at Bukit Canberra</h5>
                  {/* <p class="description">
                  View more
                  </p> */}
              </div>
          </div>
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2023</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">3 Youths: certified HPB H365 for Bukit Canberra Hawker Centre</h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
            </div>
            <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2022</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">Judge and Service Provider: World Skills Singapore Inaugural Mobile App Development Competition </h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
          </div>
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2022</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">6 Youths: IMDA Open Innovation Platform Food Rescue Challenge Winners</h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
          </div>
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2021</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">2 Youths: ColorMyDiet Open API Calories Data Analytics Platform</h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
          </div>
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2020</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">4 Youths: Covid-19 Diagnostic Software</h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
          </div>
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2019</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">e-Payments For Food Businesses (On hiatus due to T&Cs)</h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
          </div>
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2017</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">First e-Payments in IHL Food Court (Republic Poly)</h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
          </div>
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2016</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">Assistive Ordering System for Persons w Disabilities</h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
          </div>
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2015</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">Enabling Change (Unframed + SingTel)</h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
          </div>
          <div class="timeline">
              <div class="icon"></div>
              <div class="date-content">
                  <div class="date-outer">
                      <span class="date">
                              <span class="month">Year</span>
                      <span class="year">2014</span>
                      </span>
                  </div>
              </div>
              <div class="timeline-content">
                  <h5 class="title">Incorporated</h5>
                  {/* <p class="description">
                      View more
                  </p> */}
              </div>
          </div>
      </div>
      </div>
    </div>
  );
};
