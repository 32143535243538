import styles from '../../../styles/styles.module.css';

const GetSuppliers = () => {
    return (
        // changed gridTemplateColumns 'repeat(4, minmax(0, 1fr))' to 'repeat(auto-fit, minmax(150px, 1fr))'
        <div style={{display: 'grid', 'gridTemplateAreas':`'details details code code`,'gridTemplateColumns': 'repeat(auto-fit, minmax(150px, 2fr))', gap:4, maxWidth: 'inherit'}}> 
        
        {/* Details component */}
        <div style={{gridArea: 'details', marginBottom: '0px'}}>
            <div className={styles.cardbod}>
                <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#981c34'}}>Get Suppliers</h3>
                <hr />
                <p style={{marginTop: '10px'}}> This API retrieves all suppliers' information</p>

                {/* API Parameters */}
                <div style={{marginTop: '20px'}}>
                    <h5 style={{textDecoration: 'underline'}}>Parameters</h5>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. Org ID</p>
                        <p>b. Merchant ID</p>
                        <p>c. Sys Code ID - used for filtering</p>
                    </div>

                    <h5 style={{textDecoration: 'underline', marginTop: '40px'}}>Output data</h5>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. Merchant ID</p>
                        <p>b. Merchant type</p>
                        <p>c. Merchant name</p>
                        <p>d. Merchant address</p>
                        <p>e. Merchant zip code</p>
                        <p>f. Merchant mobile</p>
                        <p>g. Updated by</p>
                        <p>h. Updated on</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Code component */}
        <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
            <div style={{backgroundColor: '#981c34', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '15px' , paddingBottom: '15px'}}>
                Get Suppliers API
            </div>

            <div>
                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h5 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h5>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/common/org/{"{"}org_id{"}"}/merchants/suppliers</code>
                    </blockquote>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/common/org/{"{"}org_id{"}"}/merchant_type_sys_code={"{"}sys_code_id{"}"}</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h5 style={{marginTop:'35px', color: 'white'}}>Header</h5>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                    <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>"api-key example"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"project-id":</span> <span className={styles.codestyle3}>"project-id example"</span>
                        <br />
                        <span className={styles.codestyle1}>{"}"}</span>
                    </code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px', overflowWrap: 'break-word'}}>
                    <h5 style={{marginTop:'15px', color: 'white'}}>Sample Response</h5>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        
                        <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"data"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"["}</span>
                        <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"{"}</span>
                        <br/>

                        {/* start of data */}
                        <div style={{marginLeft: '15px'}}>
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "merchant-id example" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_type"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            {/* merchant type */}
                            <div style={{marginLeft: '15px'}}>
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"global_sys_code_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 1 <span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"sys_code_desc"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Supplier-Goods"</span>
                                <br />
                            </div>
                            <span className={styles.codestyle1} style={{marginLeft: '30px'}}>{"}"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"merchant_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Supplier 1" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"merchant_address"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Address" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"merchant_zip_code"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "123456" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"merchant_poc"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Person A" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"merchant_mobile"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "91234569" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"updated_by"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Admin" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"updated_on"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "10 Apr 2022 19:00"</span>
                            <br />

                           
                        </div>
                        <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"}"}</span><span className={styles.codestyle1}>,</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"{"}</span>
                        <br/>

                        <div style={{marginLeft: '15px'}}>
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "merchant-id example" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_type"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                            <br />
                            {/* merchant type */}
                            <div style={{marginLeft: '15px'}}>
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"global_sys_code_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 2 <span className={styles.codestyle1}>,</span></span>
                                <br />
                                <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"sys_code_desc"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Supplier-Services"</span>
                                <br />
                            </div>
                            <span className={styles.codestyle1} style={{marginLeft: '30px'}}>{"}"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"merchant_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Supplier 2" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"merchant_address"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Address" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"merchant_zip_code"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "123456" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"merchant_poc"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Person B" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"merchant_mobile"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "91234569" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"updated_by"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Admin" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '15px'}}>"updated_on"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "10 Apr 2022 19:00"</span>
                            <br />

                           
                        </div>
                        <span className={styles.codestyle1} style={{marginLeft: '30px'}}>{"}"}</span>
                        <br/>


                        {/* ending brackets */}
                        <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"}"}</span>
                        <br/>
                        <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                    </blockquote>   
                </div>

            </div>
        </div>
        </div>
    );
  };
  
  export default GetSuppliers;