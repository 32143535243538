import React, { useState, useEffect } from "react";

import { Navigation } from "./navigation";
import NetsQrSample  from "./netsQrSample";
import { Contact } from "./contact";


const NetsQrSampleLayout = () => {
  return (
    <>
      <Navigation />
      <NetsQrSample />
      <Contact  />
    </>
      
    );
  };
  
  export default NetsQrSampleLayout;