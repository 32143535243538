import { RouteType } from "./config"
import Documentation from "../pages/documentLayout"

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import GetAMerchant from "../components/views/merchant/getAMerchant";
import GetMerchants from "../components/views/merchant/getMerchants";

import React from "react";
import GetMerchantCategory from "../components/views/merchant_category/getMerchantCat";
import GetCategoryTags from "../components/views/merchant_category/getCategoryTag";
import GetASupplier from "../components/views/supplier/getASupplier";
import GetSuppliers from "../components/views/supplier/getSuppliers";
import GetAProduct from "../components/views/eMenu_product/getAProductCat";
import GetProducts from "../components/views/eMenu_product/getProducts";

const docRoutes: RouteType[] = [
  {
    index: true,
    element: <Documentation/>,
    state: "home"
  },
  {
    path: "/documentation",
    element: <Documentation/>,
    state: "introduction",
    sidebarProps: {
      displayText: "Introduction",
      icon: <HomeOutlinedIcon />
    },
  },
  {
    path: "/documentation/merchant",
    element: <Documentation />,
    state: "merchant",
    sidebarProps: {
      displayText: "Merchant",
      icon: <StorefrontIcon />
    },
    child: [
      {
        path: "/documentation/merchant/get-a-merchant",
        element: <GetAMerchant />,
        state: "merchant.getamerchant",
        sidebarProps: {
          displayText: "Get A Merchant"
        },
      },
      {
        path: "/documentation/merchant/get-merchants",
        element: <GetMerchants />,
        state: "merchant.getmerchant",
        sidebarProps: {
          displayText: "Get Merchants"
        },
      },
    ]
  },
  {
    path: "/documentation/merchant-category",
    element: <GetMerchantCategory  />,
    state: "merchant-category",
    sidebarProps: {
      displayText: "Merchant Category",
      icon: <CategoryIcon />
    },
    child: [
      {
        path: "/documentation/merchant-category/get-merchant-category",
        element: <GetMerchantCategory />,
        state: "merchant-category.getcategory",
        sidebarProps: {
          displayText: "Get Merchant Category"
        },
      },
      {
        path: "/documentation/merchant-category/get-category-tags",
        element: <GetCategoryTags />,
        state: "merchant.getcategorytags",
        sidebarProps: {
          displayText: "Get Category Tags"
        },
      },
    ]
  },
  {
    path: "/documentation/supplier",
    element: <GetASupplier />,
    state: "supplier",
    sidebarProps: {
      displayText: "Supplier",
      icon: <InventoryIcon />
    },
    child: [
      {
        path: "/documentation/supplier/get-supplier",
        element: <GetASupplier />,
        state: "supplier.getsupplier",
        sidebarProps: {
          displayText: "Get A Supplier"
        },
      },
      {
        path: "/documentation/supplier/get-suppliers",
        element: <GetSuppliers />,
        state: "supplier.getsuppliers",
        sidebarProps: {
          displayText: "Get Suppliers"
        },
      },
    ]
  },
  {
    path: "/documentation/emenu",
    element: <GetAProduct />,
    state: "emenu",
    sidebarProps: {
      displayText: "eMenu Item (Product)",
      icon: <MenuBookIcon />
    },
    child: [
      {
        path: "/documentation/emenu/get-product-category",
        element: <GetAProduct />,
        state: "emenu.getproductcategory",
        sidebarProps: {
          displayText: "Get A Product Category"
        },
      },
      {
        path: "/documentation/emenu/get-products-category",
        element: <GetProducts />,
        state: "emenu.getproductscategory",
        sidebarProps: {
          displayText: "Get Products Category"
        },
      },
    ]
  },
  {
    path: "/",
    element: <Documentation/>,
    state: "menu",
    sidebarProps: {
      displayText: "Back to home",
      icon: <ArrowBackIcon />
    },
  },
];

export default docRoutes;