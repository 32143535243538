import { Component } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {
    CCard,
    CCol,
    CRow,
} from "@coreui/react";
import commonConfigs from "../../config";
import axios from "axios";
import Button from '@mui/material/Button';
import * as payloadPlugin from './payload' //to use for debugging/hmac challenge
const Container = tw.div`relative bg-white`;
const Content = styled.div`
  ${tw`max-w-screen-xl mx-auto py-8 sm:px-20 md:px-72 lg:px-6 pb-20`}
  h2 {
    ${tw`text-2xl sm:text-3xl font-bold flex justify-center text-blue-800 pb-2`}
  }
`;

export default class ENetsSample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // merchantid: process.env.REACT_APP_MERCHANT_ID,
            // keyId: process.env.REACT_APP_MERCHANT_API_KEY,
            // secretKey: process.env.REACT_APP_MERCHANT_SCRT_KEY,
            // merchantid: "a6542f9c-1f61-4b72-a529-7a8c85f1cff4",
            // merchantid: "11c9ae03-1732-4c55-bdf7-e2f98ba79d33",
            merchantid: "8ff8e5b6-d43e-4786-8ac5-7accf8c5bd9b",
            keyId: "154eb31c-0f72-45bb-9249-84a1036fd1ca",
            secretKey: "38a4b473-0295-439d-92e1-ad26a8c60279",

            ret_ref_num: "", // this is to store the merchantTxnRef (in request api) for it to be passed to query (if timeout)

            converttime: {},
            seconds: 720,
            showGenerateButton: false,

            mobile: 82970594
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    stopTimer() {
        clearInterval(this.timer);
        this.timer = 0;
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    handleReq = () => {
        this.setState({ showGenerateButton: true }, () => {
            this.startTimer();
        });
        var body = {
            consumer_order_id: 1,
            txn_request_amt: 10,
            txn_request_merchant_id: this.state.merchantid,
            notify_mobile: 0
            // notify_mobile: parseInt(this.state.mobile)
        };
        console.log(body)
        axios.post(commonConfigs.apiUrls.RequestEnets(), body, { headers: commonConfigs.apiHeader })
            .then((res) => {
                var response = res.data.result.data;
                console.log(response);
                //set ret ref num for use if timeout in query body 
                this.setState({
                    ret_ref_num: response.txn_request_payload.msg.merchantTxnRef
                })
                //get data from return to be sent to nets plugin sendPayload
                const txnReq = JSON.stringify(response.txn_request_payload)
                const hmac = response.txn_hmac;
                console.log(txnReq, hmac)
                //hide generate enets button 
                document.getElementById('generateEnets').style.display = 'none'
                //call nets plugin function, sendPayload 
                window.sendPayLoad(txnReq, hmac, this.state.keyId);
            })
            .catch((err) => {
                console.log("API error: " + err.message);
                this.stopTimer();
            });
    }

    async countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            converttime: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            this.stopTimer();
            //Time out, call Query API to check for transaction response. To redirect according to status of transaction. e.g. Success to success page and Fail to fail page
            console.log(this.state.ret_ref_num)
            var body = {
                txn_request_ret_ref_num: this.state.ret_ref_num,
                txn_request_merchant_id: this.state.merchantid,
            };
            console.log(body);
            await axios
                .post(commonConfigs.apiUrls.QueryEnets(), body, { headers: commonConfigs.apiHeader })
                .then((res) => {
                    var response = res.data.result; //current query response is empty {}
                    console.log(response);

                    //check status 1 (success) or 2 (fail)
                    if (response.txn_status == 1) {
                        window.location.href = "/enets/success"
                    } else {
                        alert("Error code: ", response.txn_query_resp_code)
                        window.location.href = "/enets/fail"
                    }
                })
                .catch((err) => {
                    console.log("API error: " + err.message);
                });
        }
    }

    async checkTxnStatus() {
        var body = {
            txn_request_ret_ref_num: this.state.ret_ref_num,
        };
        console.log(body);
        await axios
            .post(commonConfigs.apiUrls.S2STxnStatusEnets(), body, { headers: commonConfigs.apiHeader })
            .then((res) => {
                var response = res.data.result.data;
                console.log(response);
                if (response.txn_status = 1) {
                    window.location.href = "/enets/success"
                } else {
                    console.log("Error code: ", response.txn_request_resp_code)
                    window.location.href = "/enets/fail"
                }
            })
            .catch((err) => {
                console.log("API error: " + err.message);
            });
    }

    //for hmac challenge
    hmacChallenge() {
        const txnReq = "" //replace with harcoded payload from nets portal
        const hmac = "" //replace with hmac from nets portal
        console.log(txnReq, hmac)
        //hide generate enets button 
        document.getElementById('generateEnets').style.display = 'none'
        //call nets plugin function, sendPayload 
        payloadPlugin.sendPayLoad(txnReq, hmac, this.state.keyId);
    }

    componentDidMount() {
        // this.startTimer();
        //Check if retrieval reference number exist
        if (this.state.ret_ref_num !== "") {
            //if retrieval ref num not empty, means request successfully with txn created, checkTxnStatus will render to redirect user using S2S data returned
            this.checkTxnStatus();
        }

    }

    render() {
        return (
            <div>
                <div className="content" style={{ marginTop: '10%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, height: '110vh' }}>
                    <div className="generateEnets" id="generateEnets" style={{ margin: '0' }}>
                        <h2 className="text" style={{ fontSize: '18px' }}>Sample eNETS</h2>
                        <Button variant="contained" sx={{ width: 300 }} style={{ backgroundColor: "dodgerblue", borderRadius: '10px', fontSize: '14px', textDecoration: 'none', height: "50px" }} onClick={this.handleReq}>Generate eNETS </Button>
                    </div>
                    {this.state.showGenerateButton && (
                        <Container>
                            <Content style={{ backgroundColor: '#FFFFFF' }}>
                                <h2 style={{ textAlign: 'center' }}>Checkout</h2>
                                <p>Time left: {this.state.converttime.m} : {this.state.converttime.s}</p>
                                <p><strong>DO NOT BACK OR REFRESH THIS PAGE</strong></p>
                                {/* start of nets required block for plugin  */}
                                <div id="anotherSection">
                                    <fieldset>
                                        <div id="ajaxResponse"></div>
                                    </fieldset>
                                </div>
                                {/* end of nets required block for plugin  */}
                            </Content>
                        </Container>
                    )}
                </div>
            </div>
        );
    }
}