// let commonUrl = "https://stg.beveat.openapipaas.com/api";
// let commonUrl = "https://sandbox.beveat.openapipaas.com/api"
let commonUrl = "https://sandbox.nets.openapipaas.com/api"
let openapiUrl = "https://stg.openapipaas.com/api";
// let commonUrl = "http://localhost:8080/api";

const commonConfigs = {
  redirectUrl: "http://localhost:3000/portal/signin",
  apiHeader: {
    "api-key": `${process.env.REACT_APP_SANDBOX_API_KEY}`,
    "project-id": `${process.env.REACT_APP_SANDBOX_PROJECT_ID}`
    // "api-key": `${process.env.REACT_APP_BEVEAT_API_KEY}`,
    // "project-id": `${process.env.REACT_APP_BEVEAT_PROJECT_ID}`
    // "api-key": `${process.env.REACT_APP_SGHC_API_KEY}`,
    // "project-id": `${process.env.REACT_APP_SGHC_PROJECT_ID}`
  },
  apiUrls: {
    //Nets QR
    RequestQr: () => `${commonUrl}/v1/partner-merchants/payments/nets-qr/request`,
    QueryQr: () => `${commonUrl}/v1//partner-merchants/payments/nets-qr/query`,
    ReversalQr: () => `${commonUrl}/v1//partner-merchants/payments/nets-qr/reversal`,
    // Webhook: (retrieval_ref) => `${commonUrl}/v1/common/payments/nets-qr/webhook?retrieval_ref=${retrieval_ref}`,
    Webhook: (retrieval_ref) => `${commonUrl}/v1/common/payments/nets/webhook?retrieval_ref=${retrieval_ref}`,
    //eNETS
    RequestEnets: () =>
      `${commonUrl}/v1/common/payments/enets/request`,
    QueryEnets: () =>
      `${commonUrl}/v1/common/payments/enets/query`,
    S2STxnStatusEnets: () =>
      `${commonUrl}/v1/common/payments/enets/s2s/status`,
  },
};
// const configs = Object.assign(commonConfigs, envConfigs);
export default commonConfigs;
