import React, { useState, useEffect } from "react";

import { Navigation } from "../navigation";
// import ENetsSample  from "../enets/enetsSample_philicia";
import FailSample from "../enets/txnStatus/fail";
import { Contact } from "../contact";


const FailSampleLayout = () => {
  return (
    <>
      {/* <Navigation /> */}
      <FailSample />
      {/* <Contact /> */}
    </>

  );
};

export default FailSampleLayout;