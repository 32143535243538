import { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { EventSourcePolyfill } from 'event-source-polyfill';
import commonConfigs from "../config";
import Button from '@mui/material/Button';

import netsInfo from '../assets/payments/netsInfo.png'
import loading from '../assets/payments/loading.gif'
import sad from '../assets/payments/sad.png'
import successOrder from '../assets/payments/success.png';
import failOrder from '../assets/payments/fail.png';

class NetsQrSample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      converttime: {},
      seconds: 303,
      deeplink: loading,
      retrievalref: "",
      network_code: "",
      instructions: "",
      merchantid: localStorage.getItem("merchantid"),
      orderid: localStorage.getItem("orderid"),
      amount: localStorage.getItem("amt"),
      maxquery: 100,
      errorMsg: "",
      displayQR: false,
      success: false
    };
    this.timer = 0;
    this.interval_start = 0;

    this.query_function = this.query_function.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }
  async requestQR(amt, orderId, merchantId, mobile) {
    this.setState({
      displayQR: true
    })
    var body = {
      consumer_order_id: orderId,
      amount: amt,
      merchant_id: merchantId,
      notify_mobile: mobile
    }
    console.log("This is body that is sent to request qr api", body);
    await axios.post(commonConfigs.apiUrls.RequestQr(), body, { headers: commonConfigs.apiHeader })
      .then((res) => {
        var response = res.data.result.data;
        console.log("This is the response that the request qr api returns", response);
        if (response.qr_code !== "") {
          localStorage.setItem('retrievalref', response.retrieval_ref)
          this.startTimer();
          this.setState({
            deeplink: "data:image/png;base64," + response.qr_code,
            retrievalref: response.retrieval_ref
          }
            // ,()=>this.interval_start = setInterval(this.query_function, 3000))  
          )
          this.netsQRWebhook();
        } else {
          this.setState({
            deeplink: "",
            instructions: response.instruction,
            networkcode: response.network_status
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async query_function() {
    var timeoutstatus = 0;
    if (this.state.seconds == 0) {
      timeoutstatus = 1;
    }

    if (this.state.retrievalref) {
      var body = {
        retrieval_ref: this.state.retrievalref,
        timeout_status: timeoutstatus,
        project_name: "nets_partner_merchant_sample",
        // project_name: "foodcanopy",
      }
      console.log("This is body that is sent to query api", body);
      await axios.post(commonConfigs.apiUrls.QueryQr(), body, { headers: commonConfigs.apiHeader })
        .then((res) => {
          var response = res.data.result.data;
          console.log("This is the response that the query api returns", response);

          if (response.txn_status == 0) {
          } else if (response.txn_status == 1) {
            localStorage.setItem('status', 'success');
            this.setState({
              retrievalref: "",
              success: true
            });
            document.getElementById('generateQr').style.display = 'none'
            document.getElementsByClassName('successOrder')[0].style.display = 'block'
            document.getElementsByClassName('successpayment')[0].style.display = 'none';
          } else {
            localStorage.setItem('status', 'fail');
            clearInterval(this.interval_start);
            this.setState({
              success: false
            });
            document.getElementById('generateQr').style.display = 'none'
            document.getElementsByClassName('failOrder')[0].style.display = 'block'
          }
        })
        .catch((err) => {
          console.log(err);
          localStorage.setItem('status', 'fail');
          clearInterval(this.interval_start);
          this.setState({
            success: false
          });
          document.getElementById('generateQr').style.display = 'none'
          document.getElementsByClassName('failOrder')[0].style.display = 'block'
        });
    } else {
      clearInterval(this.interval_start);
    }
  }

  netsQRWebhook() {
    if (this.eventSource) {
      this.eventSource.close();
    }
    const url = commonConfigs.apiUrls.Webhook(localStorage.getItem('retrievalref'));
    this.eventSource = new EventSourcePolyfill(url, {
      headers: commonConfigs.apiHeader,
      heartbeatTimeout: 150000
    });

    this.eventSource.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      console.log(data.message);
      console.log("Message detected");
      // If the message indicates QR code scanned successfully, call query_function
      if (data.message === 'QR code scanned') {
        if (this.eventSource) {
          this.eventSource.close();
        }
        localStorage.setItem('status', 'success');
        this.setState({
          retrievalref: "",
          success: true
        });
        document.getElementById('generateQr').style.display = 'none'
        document.getElementsByClassName('successOrder')[0].style.display = 'block'
        document.getElementsByClassName('successpayment')[0].style.display = 'none';
      } else if (data.message === 'Timeout occured') {
        if (this.eventSource) {
          this.eventSource.close();
        }
        this.query_function();
      }
    })
  }

  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      converttime: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  handleReq() {
    // this.requestQR(2.50, 14322, 'a6542f9c-1f61-4b72-a529-7a8c85f1cff4', 82970594);
    this.requestQR(10, 1, '8ff8e5b6-d43e-4786-8ac5-7accf8c5bd9b', 0);
  }
  handleCancel() {
    this.setState({
      retrievalref: ""
    }, () => window.location.reload(false))
  }
  render() {
    return (
      <div>
        <div className="content" style={{ marginTop: '10%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, height: '110vh' }}>
          <div className="button" id="generateQr" style={{ marginTop: '0' }}>
            <h2 className="text" style={{ fontSize: '18px' }}>Sample NETS QR</h2>
            <Button variant="contained" sx={{ width: 300 }} style={{ backgroundColor: "dodgerblue", borderRadius: '10px', fontSize: '14px', textDecoration: 'none', height: "50px" }} onClick={() => this.handleReq()}>Generate NETS QR </Button>
          </div>

          {this.state.networkcode !== 0 && this.state.errorMsg == "" && this.state.displayQR == true ? <div className="successpayment">
            <h2 className="text" style={{ fontSize: '20px', marginBottom: '10px', marginTop: '20px' }}>SCAN TO PAY</h2>
            <p className="text" style={{ fontSize: '18px', fontWeight: '300' }}>Scan with your NetsPay app to complete your payment</p>
            <span>
              {this.state.deeplink !== ""}
              <div id="QrImage" className="qrCode">
                <img id="netspay-mobile-link" height="auto" width="30%" src={this.state.deeplink} />
              </div>
              <h2 className="timer" style={{ fontSize: '16px' }}> {this.state.converttime.m} : {this.state.converttime.s}</h2>
            </span>
            <img id="netsinfo" height="auto" width="40%" src={netsInfo} />

            {/* <Button variant="contained" sx={{ width: 300 }} style={{ backgroundColor: "dodgerblue", borderRadius: '10px', fontSize: '14px', textDecoration: 'none', height: "50px" }} onClick={() => this.query_function()}>Send Query</Button> */}
            <div className="button" style={{ marginTop: '20px' }}>
              <Button variant="contained" sx={{ width: 300 }} style={{ backgroundColor: "#E02020", borderRadius: '10px', fontSize: '14px', textDecoration: 'none', height: "50px" }} onClick={() => this.handleCancel()}>Cancel</Button>
            </div>
          </div> : <div className="successpayment">
            {this.state.errorMsg == "" ? <h2 className="text" style={{ fontSize: '42px', marginBottom: '10px' }}>{this.state.instructions}</h2> : <h2 className="text" style={{ fontSize: '42px', marginBottom: '10px' }}>{this.state.errorMsg}</h2>}
            {this.state.errorMsg !== "" ? <><span>
              <img height="auto" width="60%" src={sad} />
            </span>
              <div className="button" style={{ marginTop: '20px' }}>
                <Button variant="contained" sx={{ width: 300 }} style={{ backgroundColor: "#E02020", borderRadius: '10px', fontSize: '14px', textDecoration: 'none', height: "50px" }} onClick={() => this.handleCancel()}>Retry</Button>
              </div></> : <></>}
          </div>}
          <div className="successOrder" style={{ display: 'none', marginTop: '20px' }}>
            <img src={successOrder} height="auto" width="30%" />
            <h2 className="text" style={{ fontSize: '18px' }}>TRANSACTION COMPLETED</h2>
            <div className="button" id="generateNewQr" style={{ marginTop: '20px' }}>
              <Button variant="contained" sx={{ width: 300 }} style={{ backgroundColor: "dodgerblue", borderRadius: '10px', fontSize: '14px', textDecoration: 'none', height: "50px" }} onClick={() => window.location.reload(false)}>Back to Test Page </Button>
            </div>
          </div>

          <div className="failOrder" style={{ display: 'none', marginTop: '20px' }}>
            <img src={failOrder} height="auto" width="30%" />
            <h2 className="text" style={{ fontSize: '18px' }}>TRANSACTION FAILED</h2>
            <div className="button" id="generateNewQr" style={{ marginTop: '20px' }}>
              <Button variant="contained" sx={{ width: 300 }} style={{ backgroundColor: "dodgerblue", borderRadius: '10px', fontSize: '14px', textDecoration: 'none', height: "50px" }} onClick={() => window.location.reload(false)}>Back to Test Page </Button>
            </div>
          </div>
          <Link to='/nets'>
            <button id="cancelbtn" style={{ visibility: 'hidden' }} >To Cart Page</button>
          </Link>
        </div>
      </div>
    );
  }
}

export default NetsQrSample;