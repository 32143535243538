import React, { useState, useEffect } from "react";

import { Navigation } from "../navigation";
// import ENetsSample  from "../enets/enetsSample_philicia";
import SuccessSample from "../enets/txnStatus/success";
import { Contact } from "../contact";


const SuccessSampleLayout = () => {
  return (
    <>
      {/* <Navigation /> */}
      <SuccessSample />
      {/* <Contact /> */}
    </>

  );
};

export default SuccessSampleLayout;