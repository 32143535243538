import React, { useState, useEffect } from "react";

import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Contact } from "./contact";
import JsonData from "../data/data.json";

const MainLayout = () => {
  return (
    <>
      <Navigation />
      <Header />
      <About  />
      <Contact  />
    </>
      
    );
  };
  
  export default MainLayout;