import { Box, Toolbar } from "@mui/material";
import layoutConfig from "../styles/layoutConfig";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Topbar from "../components/topbar";
import SidebarTest from "../components/sidebarTest";

const MainLayout = () => {
    return (
      <Box sx={{ display: "flex" }}>
        {/* <Topbar /> */}
        <Box
          component="nav"
          sx={{
            width: layoutConfig.sidebar.width,
            flexShrink: 0
          }}
        >
          <SidebarTest/>
          {/* <Sidebar /> */}
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 2,
            width: `calc(100% - ${layoutConfig.sidebar.width})`,
            minHeight: "100vh",
            backgroundColor: layoutConfig.mainBg
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    );
  };
  
  export default MainLayout;