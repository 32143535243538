import { Component } from "react";
import Button from '@mui/material/Button';
import failOrder from '../../../assets/payments/fail.png';

export default class Error extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div style={{ margin: '50px' }}>
                <div className="content" style={{ marginTop: '10%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, height: '80vh' }}>
                    <div className="failOrder" style={{ marginTop: '20px' }}>
                        <img src={failOrder} height="auto" width="30%" />
                        <h2 className="text" style={{ fontSize: '18px' }}>TRANSACTION FAILED</h2>
                        <div className="button" id="generateNewQr" style={{ marginTop: '20px' }}>
                            <Button variant="contained" sx={{ width: 300 }} style={{ backgroundColor: "dodgerblue", borderRadius: '10px', fontSize: '14px', textDecoration: 'none', height: "50px" }} onClick={() => window.location.href = "/enets"}>Back to Test Page</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}