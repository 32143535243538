import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

export const Contact = () => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-7 col-md-offset-1 contact-info">
            <h3>Policies</h3>
            <div className="contact-item">
              
              <p>
                <span>
                  <a href="/policy" style={{ color:'white'}}>Privacy Policy</a>
                </span>
                {/* {props.data ? props.data.address : "loading"} */}
              </p>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <h3>Contact Us</h3>
            <div className="contact-item">
              <p>
                <span>
                 community@beveat.com
                </span>
                {/* {props.data ? props.data.address : "loading"} */}
              </p>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 BevEat Pte Ltd
          </p>
        </div>
      </div>
    </div>
  );
};
