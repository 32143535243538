import React from "react";

export const Policy = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Privacy Policy</h2>
          <p>
            1.	We are a Social Integration Enterprise with Social Missions to provide Social Innovation Platforms that facilitate Youth and Public-Private-Community partnerships to co-create digital solutions in the F&B, Retail, Health, Youth & Community sectors.
            <br/><br/>
            2.	We may use "cookies", where a small data file is sent to your browser to store and track information about you when you enter our digital service. The cookie is used to track information such as the number of users and their frequency of use, profiles of users and their preferred digital services. While this cookie can tell us when you enter our digital services and which pages you visit, it cannot read data off your hard disk.
            <br/><br/>
            3.	You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the digital service.
            <br/><br/>
            4.	If you provide us with personally identifiable data
            <br/>
            &nbsp;&nbsp; a.	Your Personal Data will be primarily used for the provisioning of goods and services to you.
            <br/>
            &nbsp;&nbsp; b.	Where appropriate, with your permission, we may share necessary data with Government agencies, or Community partners, or Institutions of Higher Learning, or Goods and Services providers so as to serve you in the most efficient and effective way unless such sharing is prohibited by law.
            <br/>
            &nbsp;&nbsp; c.	We will NOT share your Personal Data except where such entities have been authorised by you to carry out specific services.
            <br/>
            &nbsp;&nbsp; d.	For your convenience, we may also display to you data you had previously supplied us. This will speed up the transaction and save you the trouble of repeating previous submissions. Should the data be out-of-date, please supply us the latest data.
            <br/><br/>
            5.	To safeguard your Personal Data, all electronic storage and transmission of Personal Data is secured with appropriate security technologies.
            <br/><br/>
            6.	Our digital services and websites may contain links to other organisation digital services whose data protection and privacy practices may differ from ours. We are not responsible for the content and privacy practices of these other digital services and encourage you to consult the privacy notices of those digital services.
            <br/><br/>
            7.	Please contact us at conmunity@beveat.com if you:
            <br/>
            &nbsp;&nbsp; a.	have any enquires or feedback on our data protection policies and procedures; or
            <br/>
            &nbsp;&nbsp; b.	need more information on or access to data which you have provided to us in the past.
            <br/>
            &nbsp;&nbsp; c. want to delete your account.
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};
