import { colors } from "@mui/material";

const LayoutConfig = {
    sidebar: {
        bg: "#981c34",
        color: "#ffffff",
        hoverBg: "#ab203b",
        activeBg: "#ab203b",
        // width: "300px"
    },
    mainBg: colors.grey["100"],
    topbar: {
        bg: "#fff",
        color: "#000"
    },
};

export default LayoutConfig;