import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import SidebarItem from "./sidebarItem";
import SidebarItemCollapse from "./sidebarItemCollapse";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import docRoutes from "../routes/routes";
import LayoutConfig from "../styles/layoutConfig";
import { Box, Drawer, List, ListItemButton, ListItemIcon, Stack, Toolbar, Divider } from "@mui/material";
import logo from "../assets/462x220-beveat.png"

const drawerWidth = 280;

function SidebarTest(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
        <Toolbar sx={{ marginBottom: "8px", backgroundColor: '#ffffff' }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <a href="/">
                <Box component="img" src={logo} alt="beveat logo" sx={{height: "60px", width: "120px"}}/>
              </a>
            </Stack>
        </Toolbar>
        <Provider store={store}>
            {docRoutes.map((route, index) => (
            route.sidebarProps ? (
                route.child ? (
                <SidebarItemCollapse item={route} key={index} />
                ) : (
                <SidebarItem item={route} key={index} />
                )
            ) : null
            ))}
        </Provider>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{
            backgroundColor: '#ffffff',
            color: '#981c34',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" style={{fontSize: '15px'}}>
            BevEat - Open API Documentation 
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: 230 }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                borderRight: "0px",
                backgroundColor: LayoutConfig.sidebar.bg,
                color: LayoutConfig.sidebar.color
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                borderRight: "0px",
                backgroundColor: LayoutConfig.sidebar.bg,
                color: LayoutConfig.sidebar.color
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
      </Box>
    </Box>
  );
}

export default SidebarTest;