import styles from '../../../styles/styles.module.css';

const GetMerchants = () => {
    return (
         // changed gridTemplateColumns 'repeat(4, minmax(0, 1fr))' to 'repeat(auto-fit, minmax(150px, 1fr))'
         <div style={{display: 'grid', 'gridTemplateAreas':`'details details code code`,'gridTemplateColumns': 'repeat(auto-fit, minmax(150px, 2fr))', gap:4, maxWidth: 'inherit'}}> 
        
        {/* Details component */}
        <div style={{gridArea: 'details', marginBottom: '0px'}}>
            <div className={styles.cardbod}>
                <h3 style={{fontWeight: 'bold', marginBottom: '5px', fontSize: '20px', color: '#981c34'}}>Get Merchants</h3>
                <hr />
                <p style={{marginTop: '10px'}}> This API retrieves data on a specific merchant</p>

                {/* API Parameters */}
                <div style={{marginTop: '20px'}}>
                    <h5 style={{textDecoration: 'underline'}}>Parameters</h5>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. Org ID</p>
                        <p>b. Merchant ID</p>
                    </div>

                    <h5 style={{textDecoration: 'underline', marginTop: '40px'}}>Output data</h5>
                    <div style={{paddingLeft: '10px'}}>
                        <p>a. Merchant id</p>
                        <p>b. Merchant code</p>
                        <p>c. Merchant name</p>
                        <p>d. Merchant unit no.</p>
                        <p>e. Merchant image url</p>
                        <p>f. Merchant poc</p>
                        <p>e. Updated by</p>
                        <p>g. Updated on</p>
                        <p>h. Location id</p>
                        <p>i. Location name</p>
                        <p>j. Merchant tags</p>
                        <p>k. Merchant nets details</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Code component */}
        <div style={{gridArea: 'code', marginBottom: '0px', backgroundColor: '#3f3f3f'}}>
            <div style={{backgroundColor: '#981c34', color: 'white', fontWeight: 'bold', fontSize: '17px', textAlign: 'center', paddingTop: '15px' , paddingBottom: '15px'}}>
                Get Merchants API
            </div>

            <div>
                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h5 style={{marginTop:'15px', color: 'white'}}>Request (GET)</h5>
                    <blockquote className={styles.div2} style={{wordWrap: 'break-word'}}>
                        <code className={styles.code}>/api/v1/common/org/{"{"}org_id{"}"}/merchants</code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h5 style={{marginTop:'35px', color: 'white'}}>Header</h5>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                    <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2}>"authorization":</span> <span className={styles.codestyle3}>"Bearer example"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"api-key":</span> <span className={styles.codestyle3}>"api-key example"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"project-id":</span> <span className={styles.codestyle3}>"project-id example"</span>
                        <br />
                        <span className={styles.codestyle1}>{"}"}</span>
                    </code>
                    </blockquote>
                </div>

                <div className={styles.cardbod} style={{marginLeft: '10px'}}>
                    <h5 style={{marginTop:'15px', color: 'white'}}>Sample Response</h5>
                    <blockquote className={styles.div2} style={{marginBottom:'45px'}}>
                        <code>
                        <span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        
                        <span className={styles.codestyle2}>"status":</span> <span className={styles.codestyle3}>"success"</span><span className={styles.codestyle1}>,</span>
                        <br />
                        <span className={styles.codestyle2}>"result"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle1}>{"{"}</span>
                        <br />
                        <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"data"</span><span className={styles.codestyle1}>:</span>
                        <span className={styles.codestyle1}>{"["}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '30px'}}>{"{"}</span>
                        <br/>

                        {/* start of data */}
                        <div style={{marginLeft: '15px'}}>
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "merchant-id example" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_code"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Merchant14@FoodClub_NP"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "NYP #2 Bak Chor Mee & Laksa"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_unit_no"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "01-02"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_img_url"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRfpZB0_3qGRT0vx7Jlw662goIgQc9en4esg&usqp=CAU"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_poc"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Poc14" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"updated_by"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "backened"<span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"updated_on"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "2022-07-17T07:52:30.000Z" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"location"</span><span className={styles.codestyle1}>:</span>
                            {/* location code */}
                            <span className={styles.codestyle1}>{"{"}</span>
                            <br/>
                                <div style={{marginLeft: '10px'}}>
                                    <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"location_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "location-id example"<span className={styles.codestyle1}>,</span></span>
                                    <br />
                                    <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"location_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "Block E North Canteen @ Nanyang Polytechnic"<span className={styles.codestyle1}>,</span></span>
                                    <br />
                                </div>
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"}"}</span><span className={styles.codestyle1}>,</span>
                            <br/>

                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_tags"</span><span className={styles.codestyle1}>:</span>
                            {/* merchant tags code */}
                            <span className={styles.codestyle1}>{"["}</span>
                            <br/>
                                <span className={styles.codestyle1} style={{marginLeft: '35px'}}>{"{"}</span>
                                <br/>
                                <div style={{marginLeft: '25px'}}>
                                    <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"merchant_tag_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "merchant-tag-id example"<span className={styles.codestyle1}>,</span></span>
                                    <br />
                                    <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"tag_cat_id"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "3"<span className={styles.codestyle1}>,</span></span>
                                    <br />
                                    <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"tag_cat_name"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> Chinese"<span className={styles.codestyle1}>,</span></span>
                                    <br />
                                    <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"tag_cat_img_url"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "https://image.shutterstock.com/image-vector/chinese-cuisine-restaurant-menu-vector-600w-1932699068.jpg"<span className={styles.codestyle1}>,</span></span>
                                    <br />
                                </div>
                                <span className={styles.codestyle1} style={{marginLeft: '35px'}}>{"}"}</span>
                                <br/>
                            <span className={styles.codestyle1} style={{marginLeft: '25px'}}>{"]"}</span><span className={styles.codestyle1}>,</span>
                            <br/>
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"nets_click_mid"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "mid example" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"nets_click_tid"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "tid example" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"nets_qr_mid"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "qr-mid example" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"nets_qr_tid"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle4}> "qr-tid example" <span className={styles.codestyle1}>,</span></span>
                            <br />
                            <span className={styles.codestyle2} style={{marginLeft: '10px'}}>"takeaway_charge"</span><span className={styles.codestyle1}>:</span><span className={styles.codestyle5}> 0.24 <span className={styles.codestyle1}>,</span></span>
                            <br />
                        </div>
                        <span className={styles.codestyle1} style={{marginLeft: '27px'}}>{"}"}</span>
                        <br/>
                        {/* ending brackets */}
                        <span className={styles.codestyle1} style={{marginLeft: '20px'}}>{"]"}</span>
                        <br/>
                        <span className={styles.codestyle1} style={{marginLeft: '15px'}}>{"}"}</span>
                        <br/>
                        <span className={styles.codestyle1}>{"}"}</span>
                        </code>
                    </blockquote>   
                </div>

            </div>
        </div>
        </div>
    );
  };
  
  export default GetMerchants;
